<template>
  <footer
    class="sp-bg-white sphere-footer sp-border-gray-100 dark:sp-bg-gradient-to-r dark:sp-from-[#142828] dark:sp-via-[#080f0f] dark:sp-to-black dark:sp-shadow-lg dark:sp-border-b-2 dark:sp-border-gray-900"
  >
    <div
      class="sp-grid sp-grid-cols-1 md:sp-grid-cols-2 sp-p-6 sp-items-center"
    >
      <div class="sp-flex sp-gap-5 sp-items-end">
        <div class="sp-w-40">
          <img class="mt-5" src="@/assets/images/gistda_2.png" alt="" />
        </div>
        <div class="sp-flex sp-gap-3 sp-mb-2">
          <div
            class="sp-w-7"
            @click="utility.openLink('mailto:sphere@gistda.or.th')"
          >
            <img src="@/assets/icons/mail.png" alt="mail" />
          </div>
          <div
            class="sp-w-7"
            @click="utility.openLink('https://www.facebook.com/gistda')"
          >
            <img src="@/assets/icons/facebook.png" alt="facebook" />
          </div>
          <div
            class="sp-w-7"
            @click="utility.openLink('https://www.instagram.com/gistda_space')"
          >
            <img src="@/assets/icons/instagram.png" alt="ig" />
          </div>
        </div>
      </div>
      <div
        class="sp-w-full sp-flex sp-justify-center lg:sp-justify-end sp-text-left"
      >
        <div
          class="sp-w-full lg:sp-w-1/2 sp-text-center lg:sp-text-right sp-text-white"
        >
          <p>สำนักงานพัฒนาเทคโนโลยีอวกาศและภูมิสารสนเทศ สำนักงานใหญ่</p>
          <p class="sp-w sp-text-sm">
            เลขที่ 120 อาคารรวมหน่วยราชการ (อาคารรัฐประศาสนภักดี) ชั้น 6 และชั้น
            7 ถนนแจ้งวัฒนะ แขวงทุ่งสองห้อง เขตหลักสี่ กรุงเทพฯ 10210 โทรศัพท์ 02
            143 0567
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent"
};
</script>
<style lang="scss" scoped>

 @media screen and (max-width: 768px) {
    .sphere-footer {
      padding-bottom: 5rem;
    }
  }


</style>