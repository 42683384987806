var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", {
      staticClass:
        "sp-absolute sp-w-1/2 sp-top-0 sp-right-0 sp-blur-3xl sp-h-32 sp-bg-gradient-to-l sp-z-20 sp-bg-[#2bffff37] sp-opacity-50 sp-pointer-events-none",
    }),
    _c(
      "nav",
      {
        staticClass:
          "sp-fixed sp-w-full sp-z-[100] sp-flex sp-items-center sp-justify-between lg:sp-justify-center sp-py-6 sp-bg-white sp-border-gray-100 dark:sp-bg-gradient-to-r dark:sp-from-black dark:sp-via-[#080f0f] dark:sp-to-[#142828] dark:sp-shadow-lg dark:sp-border-b-2 dark:sp-border-gray-900",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "sp-flex sp-items-center sp-flex-1 lg:sp-absolute lg:sp-inset-y-0 md:sp-left-5",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "sp-flex sp-items-center sp-justify-between sp-w-full lg:sp-w-auto sp-pl-5 lg:sp-pl-0",
              },
              [
                _vm._m(0),
                _c(
                  "div",
                  {
                    staticClass: "sp-pr-5 sp-flex sp-items-center lg:sp-hidden",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "inline-flex sp-items-center sp-justify-center sp-p-2 sp-rounded-md sp-text-gray-400 hover:sp-text-white hover:sp-bg-[#2bffff0e] focus:outline-none focus:sp-bg-[#2bffff0e] focus:sp-text-white sp-transition sp-duration-150 sp-ease-in-out",
                        attrs: {
                          type: "button",
                          id: "main-menu",
                          "aria-label": "Main menu",
                          "aria-haspopup": "true",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toggleMenu()
                          },
                        },
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "sp-h-6 sp-w-6",
                            attrs: {
                              stroke: "currentColor",
                              fill: "none",
                              viewBox: "0 0 24 24",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                                "stroke-width": "2",
                                d: "M4 6h16M4 12h16M4 18h16",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
        _c("div", { staticClass: "sp-hidden lg:sp-flex lg:sp-space-x-10" }, [
          _c(
            "a",
            {
              staticClass:
                "sp-font-normal sp-text-white dark:sp-text-white hover:sp-text-white sp-transition sp-duration-150 sp-ease-in-out",
              attrs: { href: "#about" },
              on: { click: _vm.onClickNav },
            },
            [_vm._v(" " + _vm._s(_vm.$t("landing.navbar.about")) + " ")]
          ),
          _c(
            "a",
            {
              staticClass:
                "sp-font-normal sp-text-white dark:sp-text-white hover:sp-text-white sp-transition sp-duration-150 sp-ease-in-out",
              attrs: { href: "#apis" },
              on: { click: _vm.onClickNav },
            },
            [_vm._v(_vm._s(_vm.$t("landing.navbar.api")))]
          ),
          _c(
            "a",
            {
              staticClass:
                "sp-font-normal sp-text-white dark:sp-text-white hover:sp-text-white sp-transition sp-duration-150 sp-ease-in-out",
              attrs: { href: "#map-marker" },
              on: { click: _vm.onClickNav },
            },
            [_vm._v(_vm._s(_vm.$t("landing.navbar.mapMarker")))]
          ),
          _c(
            "a",
            {
              staticClass:
                "sp-font-normal sp-text-white dark:sp-text-white hover:sp-text-white sp-transition sp-duration-150 sp-ease-in-out",
              attrs: { href: "#data-cube" },
              on: { click: _vm.onClickNav },
            },
            [_vm._v(_vm._s(_vm.$t("landing.navbar.dataCube")))]
          ),
          _c(
            "a",
            {
              staticClass:
                "sp-font-normal sp-text-white dark:sp-text-white hover:sp-text-white sp-transition sp-duration-150 sp-ease-in-out",
              attrs: { href: "#solutions" },
              on: { click: _vm.onClickNav },
            },
            [_vm._v(_vm._s(_vm.$t("landing.navbar.solutions")))]
          ),
          _c(
            "a",
            {
              staticClass:
                "sp-font-normal sp-text-white dark:sp-text-white hover:sp-text-white sp-transition sp-duration-150 sp-ease-in-out",
              attrs: { href: "#pricing" },
              on: { click: _vm.onClickNav },
            },
            [_vm._v(" " + _vm._s(_vm.$t("landing.navbar.pricing")) + " ")]
          ),
        ]),
        _c(
          "div",
          {
            staticClass:
              "sp-hidden lg:sp-absolute lg:sp-flex lg:sp-items-center lg:sp-justify-end lg:sp-inset-y-0 lg:sp-right-5",
          },
          [
            _c(
              "div",
              { staticClass: "sp-inline-flex sp-text-sm sp-items-center" },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "sp-inline-flex sp-items-center sp-py-2 sp-border sp-border-transparent sp-text-base sp-leading-6 sp-font-normal focus:outline-none sp-transition sp-duration-150 sp-ease-in-out",
                    class:
                      _vm.$i18n.locale === "th"
                        ? "sp-text-[#2BFFFF]"
                        : "sp-text-white",
                    on: {
                      click: function ($event) {
                        return _vm.changeLocale("th")
                      },
                    },
                  },
                  [_vm._v(" TH ")]
                ),
                _c(
                  "span",
                  { staticClass: "sp-text-white sp-text-lg sp-pl-1 sp-pr-1" },
                  [_vm._v("/")]
                ),
                _c(
                  "button",
                  {
                    staticClass:
                      "inline-flex sp-items-center sp-py-2 sp-border sp-border-transparent sp-text-base sp-leading-6 sp-font-normal focus:outline-none sp-transition sp-duration-150 sp-ease-in-out",
                    class:
                      _vm.$i18n.locale === "en"
                        ? "sp-text-[#2BFFFF]"
                        : "sp-text-white",
                    on: {
                      click: function ($event) {
                        return _vm.changeLocale("en")
                      },
                    },
                  },
                  [_vm._v(" EN ")]
                ),
              ]
            ),
            _c("span", { staticClass: "sp-text-white sp-ml-2 sp-mr-2" }, [
              _vm._v("|"),
            ]),
            _vm.profile && _vm.profile.id
              ? _c(
                  "div",
                  { staticClass: "profile" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          disabled: "",
                          elevation: "3",
                          color: "#c3c3c3",
                          fab: "",
                          height: "45",
                          width: "45",
                        },
                      },
                      [
                        _vm.profileImg
                          ? _c("v-img", {
                              staticStyle: { "border-radius": "32px" },
                              attrs: {
                                width: "25",
                                src: _vm.profileImg,
                                alt: "Profile Image",
                              },
                            })
                          : _c("v-img", {
                              staticClass: "ma-4",
                              attrs: {
                                width: "25",
                                src: _vm.utility.getImgSrc(
                                  "ic-account-white.svg"
                                ),
                                alt: "Profile Image",
                              },
                            }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "main-header-profile text-left ml-3",
                        staticStyle: {
                          width: "90px",
                          "line-height": "1.25rem",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "one-line text-lg word-break-all font-weight-semi-bold",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.profile.displayName ||
                                    _vm.profile.username
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _vm.profile.organization
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "text-gray text-sm font-weight-light one-line word-break-all",
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.profile.organization) + " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "v-menu",
                          {
                            attrs: { "offset-y": "", left: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "ml-3",
                                              attrs: { icon: "" },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "header-icon text-white",
                                              attrs: { large: "" },
                                            },
                                            [_vm._v("mdi-menu-down")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1365223273
                            ),
                          },
                          [
                            _c(
                              "v-list",
                              { staticClass: "text-left py-0" },
                              [
                                _c(
                                  "v-list-item",
                                  { attrs: { to: "/dashboard" } },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v("Dashboard"),
                                    ]),
                                  ],
                                  1
                                ),
                                _c("v-divider"),
                                _c(
                                  "v-list-item",
                                  { on: { click: _vm.logout } },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(_vm._s(_vm.$t("app.logout"))),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.profile
              ? _c("span", { staticClass: "inline-flex" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "inline-flex sp-items-center sp-py-2 sp-border sp-border-transparent sp-text-base sp-leading-6 sp-font-normal sp-text-white focus:outline-none sp-transition sp-duration-150 sp-ease-in-out",
                      on: { click: _vm.login },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("landing.navbar.login")) + " ")]
                  ),
                ])
              : _vm._e(),
            !_vm.profile
              ? _c(
                  "span",
                  {
                    staticClass:
                      "sp-inline-flex sp-rounded-lg sp-shadow sp-ml-2",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "sp-text-black sp-bg-[#2BFFFF] sp-hover:bg-blue-800 sp-focus:ring-4 sp-focus:outline-none sp-focus:ring-blue-300 sp-font-th sp-rounded-3xl sp-text-sm sp-px-4 sp-py-2 sp-text-center dark:sp-bg[#2BFFFF] dark:sp-hover:bg-blue-700 dark:sp-focus:ring-blue-800",
                        attrs: { type: "button" },
                        on: { click: _vm.signup },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("landing.navbar.register")) + " "
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "sp-flex sp-text-left sp-flex-col sp-transition-all sp-max-h-0 sp-overflow-hidden dark:sp-bg-gradient-to-r dark:sp-from-black dark:sp-via-[#080f0f] dark:sp-to-[#142828] dark:sp-shadow-lg dark:sp-border-b-2 dark:sp-border-gray-900 sp-w-full sp-absolute sp-top-full sp-left-0 lg:sp-hidden",
            attrs: { id: "mobile-menu" },
          },
          [
            _c(
              "a",
              {
                staticClass: "sp-text-white sp-py-2 sp-px-4",
                attrs: { href: "#about" },
              },
              [_vm._v("About")]
            ),
            _c(
              "a",
              {
                staticClass: "sp-text-white sp-py-2 sp-px-4",
                attrs: { href: "#apis" },
              },
              [_vm._v("APIs")]
            ),
            _c(
              "a",
              {
                staticClass: "sp-text-white sp-py-2 sp-px-4",
                attrs: { href: "#map-marker" },
              },
              [_vm._v("Map Marker")]
            ),
            _c(
              "a",
              {
                staticClass: "sp-text-white sp-py-2 sp-px-4",
                attrs: { href: "#data-cube" },
              },
              [_vm._v("Data Cube")]
            ),
            _c(
              "a",
              {
                staticClass: "sp-text-white sp-py-2 sp-px-4",
                attrs: { href: "#solutions" },
              },
              [_vm._v("Solutions")]
            ),
            _c(
              "a",
              {
                staticClass: "sp-text-white sp-py-2 sp-px-4",
                attrs: { href: "#pricing" },
              },
              [_vm._v("Pricing")]
            ),
            _c("div", {
              staticClass: "sp-border-t sp-border-gray-700 sp-my-4",
            }),
            _c(
              "div",
              { staticClass: "sp-flex sp-justify-center sp-space-x-4 sp-py-2" },
              [
                _c(
                  "button",
                  {
                    class:
                      _vm.$i18n.locale === "th"
                        ? "sp-text-[#2BFFFF] sp-font-normal"
                        : "sp-text-white sp-font-normal",
                    on: {
                      click: function ($event) {
                        return _vm.changeLocale("th")
                      },
                    },
                  },
                  [_vm._v(" TH ")]
                ),
                _c("span", { staticClass: "sp-text-white" }, [_vm._v("/")]),
                _c(
                  "button",
                  {
                    class:
                      _vm.$i18n.locale === "en"
                        ? "sp-text-[#2BFFFF] sp-font-normal"
                        : "sp-text-white sp-font-normal",
                    on: {
                      click: function ($event) {
                        return _vm.changeLocale("en")
                      },
                    },
                  },
                  [_vm._v(" EN ")]
                ),
              ]
            ),
            _vm.profile && _vm.profile.id
              ? _c(
                  "div",
                  { staticClass: "profile" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          disabled: "",
                          elevation: "3",
                          color: "#c3c3c3",
                          fab: "",
                          height: "45",
                          width: "45",
                        },
                      },
                      [
                        _vm.profileImg
                          ? _c("v-img", {
                              staticStyle: { "border-radius": "32px" },
                              attrs: {
                                width: "25",
                                src: _vm.profileImg,
                                alt: "Profile Image",
                              },
                            })
                          : _c("v-img", {
                              staticClass: "ma-4",
                              attrs: {
                                width: "25",
                                src: _vm.utility.getImgSrc(
                                  "ic-account-white.svg"
                                ),
                                alt: "Profile Image",
                              },
                            }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "main-header-profile text-left ml-3",
                        staticStyle: {
                          width: "90px",
                          "line-height": "1.25rem",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "one-line text-lg word-break-all font-weight-semi-bold",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.profile.displayName ||
                                    _vm.profile.username
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _vm.profile.organization
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "text-gray text-sm font-weight-light one-line word-break-all",
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.profile.organization) + " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "v-menu",
                          {
                            attrs: { "offset-y": "", left: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "ml-3",
                                              attrs: { icon: "" },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "header-icon text-white",
                                              attrs: { large: "" },
                                            },
                                            [_vm._v("mdi-menu-down")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1365223273
                            ),
                          },
                          [
                            _c(
                              "v-list",
                              { staticClass: "text-left py-0" },
                              [
                                _c(
                                  "v-list-item",
                                  { attrs: { to: "/dashboard" } },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v("Dashboard"),
                                    ]),
                                  ],
                                  1
                                ),
                                _c("v-divider"),
                                _c(
                                  "v-list-item",
                                  { on: { click: _vm.logout } },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(_vm._s(_vm.$t("app.logout"))),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.profile
              ? _c(
                  "div",
                  {
                    staticClass:
                      "sp-flex sp-justify-center sp-space-x-4 sp-mt-2 sp-mb-5",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "sp-text-white sp-px-4 sp-py-2 sp-rounded-3xl",
                        on: { click: _vm.login },
                      },
                      [_vm._v(" Log in ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass:
                          "sp-bg-[#2BFFFF] sp-text-black sp-rounded-3xl sp-px-4 sp-py-2",
                        on: { click: _vm.signup },
                      },
                      [_vm._v(" Sign up ")]
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass:
          "sp-flex sp-items-center sp-space-x-3 rtl:sp-space-x-reverse",
        attrs: { href: "#" },
      },
      [
        _c("img", {
          staticClass: "sp-h-12",
          attrs: {
            src: require("@/assets/images/logo-sphere-alt.png"),
            alt: "sphere-logo",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }