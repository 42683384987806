<template>
  <section class="sp-bg-[#0E0F0F] sp-pt-20" id="solutions">
    <div>
      <h1 class="sp-text-white sp-text-center sp-text-4xl">
        {{ $t("landing.solutions") }}
      </h1>
    </div>
    <div class="sp-max-w-screen-xl sp-mx-auto sp-justify-center">
      <ul
        class="sp-flex sp-flex-wrap sp-justify-center -sp-mb-px sp-text-gray-500 sp-text-lg"
      >
        <li class="sp-me-4" v-for="(tab, index) in items" :key="index + 1">
          <button
            @click="changeTab(index)"
            class="sp-inline-block sp-text-white sp-p-4 sp-border-b-2 sp-border-transparent"
          >
            {{ tab.tabName }}
          </button>
        </li>
      </ul>
      <v-tabs-items v-model="tab" class="sphere-solutions-content">
        <v-tab-item v-for="(item, index) in items" :key="index">
          <div class="sp-mx-auto sp-max-w-screen-xl sp-p-5 lg:sp-p-5">
            <div
              class="sp-grid sp-grid-cols-1 lg:sp-grid-cols-2 sp-items-center sp-gap-5"
            >
              <div class="showcase-img">
                <img
                  width="100%"
                  :src="utility.getImgSrc(item.tabImage)"
                  alt="no-image"
                  srcset=""
                />
              </div>

              <div
                class="sp-px-10 sp-py-10 sp-text-white sp-border-1 sp-rounded-3xl sulutions-content"
              >
                <h2 class="sp-text-3xl">{{ item.tabName }}</h2>
                <p
                  :key="locale"
                  class="sp-pt-10 sp-text-gray-300 sp-font-thin"
                  v-html="
                    locale === 'th' ? item.tabContent.th : item.tabContent.en
                  "
                ></p>

                <button @click="utility.openLink(item.url)" class="sulutions-explore">
                  Explore {{ item.tabName }}
                </button>
              </div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </section>
</template>

<script>
export default {
  name: "SolutionsComponent",
  data() {
    return {
      tab: 0,
      locale: this.$i18n.locale,
      items: [
        {
          tabName: "Where",
          tabContent: {
            th: "ระบบการค้นหาเส้นทางแบบ Turn by turn navigation ที่ผู้ใช้งานสามารถป้อนจุดเริ่มต้น และจุดปลายทางได้ พร้อมทั้งมีเสียงนำทางประกอบ สามารถ download ได้ทั้งบน iOS และ Android",
            en: "Turn by turn navigation system that allows users to enter the starting point and destination, complete with voice guidance. It can be downloaded on both iOS and Android.",
          },
          tabImage: "where.png",
          url: "https://where.gistda.or.th/",
        },
        {
          tabName: "Better Crop",
          tabContent: {
            th: "ตัวอย่างเว็บไซต์สำหรับให้บริการสอบถามข้อมูลพื้นที่เหมาะสมในการปลูกพืชเศรษกิจ 6 ชนิด <br> ได้แก่ อ้อย ข้าว ข้าวโพด มันสำปะหลัง ยางพารา และปาล์ม <br>เพียงผู้ใช้งานวาดขอบเขตบริเวณพื้นที่ที่สนใจ ระบบจะทำการการวิเคราะห์พื้นที่ และแสดงความเหมาะสมของพืชแต่ละชนิด รวมถึงราคาต้นทุนในการเพาะปลูกและราคาขายในปัจจุบัน ได้อีกด้วย",
            en: "A website that provides information on suitable areas for planting 6 types of cash crops, including sugarcane, rice, corn, cassava, rubber, and palm. Users simply draw the boundary of the area of interest, and the system will analyze the area and show the suitability of each crop, as well as the cost of cultivation and current selling prices.",
          },
          tabImage: "showcase-crop@2x.png",
          url: "https://opendata.gistda.or.th/better_crop"
        },
        {
          tabName: "Flood Information",
          tabContent: {
            th: "ตัวอย่างเว็บไซต์สำหรับให้บริการสอบถามข้อมูลพื้นที่น้ำท่วมซ้ำซากในรอบ 11 ปี (พ.ศ. 2554 - พ.ศ. 2564) โดยผู้ใช้งานสามารถเลือกสอบถามพื้นที่น้ำท่วมจากการเลือกขอบเขตจังหวัดและอำเภอ หรือสอบถามพื้นที่น้ำท่วมโดยรอบขอบเขตของการปักหมุดบนแผนที่ที่สนใจได้",
            en: "A website that provides information on flood-prone areas over an 11-year period (2011-2023). Users can choose to inquire about flood-prone areas by selecting the boundaries of provinces and districts, or inquire about flood-prone areas by drawing boundaries on the map.",
          },
          tabImage: "showcase-flood@2x.png",
          url: "https://opendata.gistda.or.th/flood_info"
        },
        {
          tabName: "Drought Information",
          tabContent: {
            th: "ตัวอย่างเว็บไซต์สำหรับให้บริการสอบถามข้อมูลภัยแล้งซ้ำซาก 10 ปี (พ.ศ. 2548 -2557) ด้วยการแสดงในรูปแบบแผนที่ออนไลน์ <br> ผู้ใช้งานสามารถเลือกแสดงผลเปอร์เซ็นต์ของระดับภัยแล้งซ้ำซากในระดับตำบล โดยการเลือกจังหวัดและอำเภอที่สนใจ",
            en: "A website that provides information on droughts over the past 10 years (2005-2014) in the form of an online map. Users can choose to display the percentage of drought severity at the sub-district level by selecting the province and district of interest.",
          },
          tabImage: "showcase-drought@2x.png",
          url: "https://opendata.gistda.or.th/drought_info"
        },
      ],
    };
  },
  watch: {
    "$i18n.locale": function () {
      this.locale = this.$i18n.locale;
    },
  },
  methods: {
    changeTab(tab) {
      this.tab = tab;
    },
  },
};
</script>

<style lang="scss">
#solutions {
  scroll-margin-top: 100px;
}
.showcase-img {
  width: 100%;
  height: 100%;
  img {
    border-radius: 25px;
  }
}
.sphere-solutions-content {
  min-height: 600px;
}

.sulutions-content {
  position: relative;
  background: linear-gradient(
    45deg,
    rgba(43, 255, 255, 0.1) 0%,
    rgba(43, 255, 255, 0.02) 50%,
    rgba(43, 255, 255, 0.05) 100%
  );

  background-size: 200% 200%;
  border: 2px solid #0f4848;
  background-repeat: no-repeat;
  background-position: 100% 0%;
  animation: shimmer 5s ease-in-out infinite normal;
  text-align: left;
  padding: 20px;

  .sulutions-explore {
    padding: 10px 15px 10px 15px;
    border: 1px solid #ffff;
    border-radius: 25px;
  }
}

@keyframes shimmer {
  0% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 100% 0%;
  }
}
</style>
