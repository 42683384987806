var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "sp-bg-black sp-relative sp-mx-auto" }, [
    _c("div", {
      staticClass:
        "sp-absolute sp-bg-[url('/assets/images/line-left.png')] sp-h-[600px] sp-w-[400px] sp-bg-contain sp-bg-no-repeat",
    }),
    _c("div", {
      staticClass:
        "sp-absolute sp-bg-[url('/assets/images/line-right.png')] sp-right-0 sp-bottom-0 sp-z-0 sp-h-[600px] sp-w-[400px] sp-bg-contain sp-bg-no-repeat",
    }),
    _c(
      "div",
      {
        staticClass:
          "sp-px-5 sp-py-5 sp-text-white sp-mx-auto sp-max-w-screen-xl",
        staticStyle: { position: "relative" },
      },
      [
        _c(
          "h1",
          { staticClass: "sp-text-2xl sp-text-center sp-pt-10 sp-pb-10" },
          [_vm._v(" " + _vm._s(_vm.$t("landing.trustedMe")) + " ")]
        ),
        _vm._m(0),
        _c(
          "div",
          {
            staticClass:
              "sp-w-full sp-relative sp-h-[450px] sp-rounded-md sp-bg-gradient-to-bl sp-from-[#237d7d] sp-via-black sp-via-90% sp-to-black sp-p-0.5",
            attrs: { id: "pricing" },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "sp-w-full sp-h-full sp-rounded-md sp-flex sp-items-center sp-justify-center",
              },
              [
                _c("div", [
                  _c(
                    "h2",
                    {
                      staticClass:
                        "sp-text-5xl sp-font-bold sp-text-white sp-text-center",
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("landing.pricingTopic")) + " ")]
                  ),
                  _c("p", { staticClass: "sp-mt-5 sp-font-thin" }, [
                    _vm._v(" " + _vm._s(_vm.$t("landing.pricing_des")) + " "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "sp-flex sp-justify-center sp-pt-4" },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "sp-rounded-lg sp-bg-[#2BFFFF] sp-px-5 sp-py-3",
                          staticStyle: { color: "black" },
                          on: { click: _vm.signup },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("landing.register")) + " ")]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "sp-grid sp-grid-cols-2 lg:sp-grid-cols-4 sp-gap-14 sp-pt-10 sp-pb-24",
      },
      [
        _c("div", [
          _c("img", {
            attrs: { src: require("@/assets/images/gistda.png"), alt: "" },
          }),
        ]),
        _c("div", [
          _c("img", {
            attrs: { src: require("@/assets/images/gistda.png"), alt: "" },
          }),
        ]),
        _c("div", [
          _c("img", {
            attrs: { src: require("@/assets/images/gistda.png"), alt: "" },
          }),
        ]),
        _c("div", [
          _c("img", {
            attrs: { src: require("@/assets/images/gistda.png"), alt: "" },
          }),
        ]),
        _c("div", [
          _c("img", {
            attrs: { src: require("@/assets/images/gistda.png"), alt: "" },
          }),
        ]),
        _c("div", [
          _c("img", {
            attrs: { src: require("@/assets/images/gistda.png"), alt: "" },
          }),
        ]),
        _c("div", [
          _c("img", {
            attrs: { src: require("@/assets/images/gistda.png"), alt: "" },
          }),
        ]),
        _c("div", [
          _c("img", {
            attrs: { src: require("@/assets/images/gistda.png"), alt: "" },
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }