<template>
  <div class="sp-bg-black" id="sphere-landing">
    <LandingHeader />
    <Hero />
    <MainFeature />
    <Feature />
    <Solutions />
    <Clients />
    <Footer />
  </div>
</template>
<script>
import LandingHeader from "@/components/landing-page/LandingHeader.vue";
import Hero from "@/components/landing-page/Hero.vue";
import MainFeature from "@/components/landing-page/MainFeature.vue";
import Feature from "@/components/landing-page/Feature.vue";
import Solutions from "@/components/landing-page/Solutions.vue";
import Clients from "@/components/landing-page/Clients.vue";
import Footer from "@/components/landing-page/Footer.vue";
// import AOS from "aos";
import "aos/dist/aos.css";
import "animate.css";

export default {
  name: "LandingViewNew",
  components: {
    LandingHeader,
    Hero,
    MainFeature,
    Feature,
    Solutions,
    Clients,
    Footer,
  },
  mounted() {
    const elements = document.querySelectorAll("[on-scroll]");
    elements.forEach((element) => {
      element.setAttribute("style", "opacity: 0;");
    });
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.target.classList.contains("animate__animated")) {
              return;
            } else {
              entry.target.classList.add("animate__animated");
              const animation = entry.target.getAttribute("on-scroll");
              entry.target.classList.add("animate__" + animation);
            }
            observer.unobserve(entry.target);
          } else {
            entry.target.classList.remove("animate__animated");
            const animation = entry.target.getAttribute("on-scroll");
            entry.target.classList.remove("animate__" + animation);
          }
        });
      },
      {
        threshold: 0.5,
      }
    );
    elements.forEach((element) => observer.observe(element));
  },
  methods: {
    runOnScroll() {
      console.log("scrolling");
    },
    runOnWheel() {},
  },
};
</script>

<style lang="scss">
@import "@/assets/style/sphere.scss";
</style>
