import Vue from 'vue'
import VueRouter from 'vue-router'
// import LandingView from '@/views/LandingView.vue'

import LandingView from '@/views/LandingViewNew.vue'

Vue.use(VueRouter)

const ifAuthenticated = (to, from, next) => {
  if (Vue.$keycloak.authenticated) {
    next()
    return
  }
  window.location.href = Vue.$keycloak.createLoginUrl({ locale: 'th' })
}

const routes = [
  {
    path: '/',
    name: 'landing',
    component: LandingView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/AboutView.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '@/views/PrivacyView.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "terms" */ '@/views/TermsView.vue')
  },
  {
    path: '/terms-api',
    name: 'terms-api',
    component: () => import(/* webpackChunkName: "terms-api" */ '@/views/TermsApiView.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/UserDashboardView.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/api-keys',
    name: 'api-keys',
    component: () => import(/* webpackChunkName: "api-keys" */ '@/views/UserApiKeysView.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/UserProfileView.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import(/* webpackChunkName: "wallet" */ '@/views/UserWalletView.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/storage',
    name: 'storage',
    component: () => import(/* webpackChunkName: "storage" */ '@/views/UserStorageUsageView.vue'),
    beforeEnter: ifAuthenticated
  },
  // admin pages
  {
    path: '/admin-dashboard/map-transactions',
    name: 'admin-dashboard',
    component: () => import(/* webpackChunkName: "admin-dashboard" */ '@/views/AdminDashboardView.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/admin-dashboard/credit-payment',
    name: 'admin-dashboard-credit-payment',
    component: () => import(/* webpackChunkName: "admin-dashboard-credit-payment" */ '@/views/AdminDashboardCreditPaymentView.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/admin-stats',
    name: 'admin-stats',
    component: () => import(/* webpackChunkName: "admin-stats" */ '@/views/AdminStatisticsView.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/admin-user',
    name: 'admin-user',
    component: () => import(/* webpackChunkName: "admin-user" */ '@/views/AdminUserView.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/admin-settings/general',
    name: 'admin-settings',
    component: () => import(/* webpackChunkName: "admin-settings" */ '@/views/AdminSettingsView.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/admin-settings/role',
    name: 'admin-settings-role',
    component: () => import(/* webpackChunkName: "admin-settings-role" */ '@/views/AdminSettingsRoleView.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/admin-settings/permission',
    name: 'admin-settings-permission',
    component: () => import(/* webpackChunkName: "admin-settings-permission" */ '@/views/AdminSettingsPermissionView.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/admin-settings/price',
    name: 'admin-settings-price',
    component: () => import(/* webpackChunkName: "admin-settings-price" */ '@/views/AdminSettingsPriceView.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/admin-activity-log',
    name: 'admin-activity-log',
    component: () => import(/* webpackChunkName: "admin-activity-log" */ '@/views/AdminActivityLogView.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/admin-map-setup/crop-info',
    name: 'admin-map-setup-crop-info',
    component: () => import(/* webpackChunkName: "admin-map-setup-crop-info" */ '@/views/AdminMapSetupCropInfoView.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/admin-map-setup/road-closures',
    name: 'admin-map-setup-road-closures',
    component: () => import(/* webpackChunkName: "admin-map-setup-road-closures" */ '@/views/AdminMapSetupRoadClosuresView.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/open-data-cube',
    name: 'open-data-cube',
    component: () => import(/* webpackChunkName: "open-data-cube" */ '@/views/OpenDataCubeView.vue'),
    beforeEnter: ifAuthenticated
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

export default router
