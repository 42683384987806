var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "section",
      {
        staticClass:
          "-sp-mt-52 sp-relative sp-bg-black sp-mx-auto sp-max-w-screen-xl sp-p-5 lg:sp-p-0",
      },
      [
        _c("div", { staticClass: "preview" }, [
          _c(
            "div",
            {
              staticClass:
                "sp-flex sp-justify-center sp-text-white sp-text-center",
            },
            [
              _c("div", [
                _c(
                  "h1",
                  {
                    staticClass: "sp-text-4xl",
                    attrs: { "on-scroll": "fadeInDown" },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("landing.mapLayerTopic")) + " ")]
                ),
                _c(
                  "p",
                  {
                    staticClass: "sp-font-thin sp-text-lg sp-mt-5",
                    attrs: { "on-scroll": "fadeInDown" },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("landing.mapLayerTopicDes")) + " "
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _c("div", {
            staticClass: "map-preview sp-w-full sp-rounded-xl",
            attrs: { id: "map-preview" },
          }),
        ]),
        _c("div", { staticClass: "sp-mt-10" }, [
          _c(
            "div",
            {
              staticClass:
                "sp-flex sp-justify-center sp-text-white sp-text-center",
            },
            [
              _c("div", { staticClass: "sp-mb-10" }, [
                _c(
                  "h1",
                  {
                    staticClass: "sp-text-4xl",
                    attrs: { "on-scroll": "fadeInDown" },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("landing.colorBlindTopic")) + " "
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _c("div", { staticClass: "sp-flex sp-justify-center" }, [
            _c("div", { staticClass: "sp-w-full" }, [
              _vm._m(0),
              _c(
                "p",
                {
                  staticClass:
                    "sp-text-white sp-text-center sp-font-thin sp-text-lg",
                },
                [_vm._v(" " + _vm._s(_vm.$t("landing.colorBlindDes")) + " ")]
              ),
            ]),
          ]),
        ]),
      ]
    ),
    _c("section", { staticClass: "sp-bg-black" }, [
      _c("div", { staticClass: "sp-flex sp-justify-center sp-relative" }, [
        _c(
          "div",
          {
            staticClass:
              "sp-absolute sp-opacity-20 sp-pointer-events-none sp-bottom-0 sp-flex sp-translate-y-0 sp-z-0 sp-justify-center sp-overflow-hidden [mask-image:radial-gradient(50%_50%_at_50%_50%,white,transparent)]",
          },
          [
            _c(
              "svg",
              {
                staticClass:
                  "sp-h-[80rem] sp-w-[100%] sp-flex-none sp-stroke-white sp-opacity-30",
                attrs: { "aria-hidden": "true" },
              },
              [
                _c("defs", [
                  _c(
                    "pattern",
                    {
                      attrs: {
                        id: "e9033f3e-f665-41a6-84ef-756f6778e6fe",
                        width: "70",
                        height: "70",
                        x: "50%",
                        y: "50%",
                        patternUnits: "userSpaceOnUse",
                        patternTransform: "translate(-100,0)",
                      },
                    },
                    [
                      _c("path", {
                        attrs: { d: "M.5 100V.5H100", fill: "none" },
                      }),
                    ]
                  ),
                ]),
                _c("rect", {
                  attrs: {
                    width: "100%",
                    height: "100%",
                    "stroke-width": "0",
                    fill: "url(#e9033f3e-f665-41a6-84ef-756f6778e6fe)",
                  },
                }),
              ]
            ),
          ]
        ),
        _c("div", {
          staticClass:
            "sp-absolute sp-top-0 sp-bottom-0 sp-z-50 sp-w-[90%] sp-animate-pulse",
          attrs: { id: "particles-2" },
        }),
        _c("div", {
          staticClass:
            "sp-absolute sp-z-50 sp-w-[300px] lg:sp-w-[800px] sp-top-0 lg:sp-top-14 sp-h-[400px] lg:sp-h-[800px] sp-bg-[#237d7d] sp-rounded-full sp-mix-blend-screen sp-blur-[200px]",
          attrs: { id: "swiper-line" },
        }),
        _c(
          "div",
          {
            staticClass:
              "sp-max-w-screen-xl sp-relative sp-z-30 disabled-person-ring-container",
          },
          [
            _c("img", {
              staticClass: "disabled-ring",
              attrs: {
                src: require("@/assets/images/disabled_person_ring.png"),
                alt: "",
              },
            }),
            _c(
              "div",
              {
                staticClass: "disabled-description",
                staticStyle: { color: "white" },
              },
              [_vm._v(" " + _vm._s(_vm.$t("landing.disabledPerson")) + " ")]
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "div",
        {
          staticClass:
            "sp-relative sp-bg-[url('/assets/images/red_green.png')] sp-bg-[size:100%_100%] sp-bg-no-repeat sp-flex sp-items-center sp-justify-center mobile-bg",
        },
        [
          _c("img", {
            staticClass: "sp-rounded-xl sp-absolute sp-pointer-events-none",
            attrs: {
              src: require("@/assets/images/normal_mode.png"),
              width: "600px",
              alt: "normal_mode",
              srcset: "",
            },
          }),
          _c("img", {
            staticClass: "sp-rounded-xl sp-relative sp-pointer-events-none",
            staticStyle: { "clip-path": "inset(0 50% 0 0)" },
            attrs: {
              src: require("@/assets/images/deuteranopia_mode.png"),
              id: "compare-img",
              width: "600px",
              alt: "deuteranopia_mode",
            },
          }),
          _c("div", { staticClass: "slider-bar" }, [
            _c("div", {
              staticClass:
                "sp-absolute sp-z-50 sp-top-0 sp-left-1/2 sp-h-full sp-w-1 sp-bg-[#2BFFFF] sp-blur-[1px] sp-cursor-pointer",
              attrs: { id: "slider-bar" },
            }),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }