<template>
  <section class="sp-bg-black sp-relative sp-mx-auto">
    <div
      class="sp-absolute sp-bg-[url('/assets/images/line-left.png')] sp-h-[600px] sp-w-[400px] sp-bg-contain sp-bg-no-repeat"
    ></div>
    <div
      class="sp-absolute sp-bg-[url('/assets/images/line-right.png')] sp-right-0 sp-bottom-0 sp-z-0 sp-h-[600px] sp-w-[400px] sp-bg-contain sp-bg-no-repeat"
    ></div>
    <div class="sp-px-5 sp-py-5 sp-text-white sp-mx-auto sp-max-w-screen-xl" style="position: relative;">
      <h1 class="sp-text-2xl sp-text-center sp-pt-10 sp-pb-10">
        {{ $t("landing.trustedMe") }}
      </h1>
      <div
        class="sp-grid sp-grid-cols-2 lg:sp-grid-cols-4 sp-gap-14 sp-pt-10 sp-pb-24"
      >
        <div>
          <img src="@/assets/images/gistda.png" alt="" />
        </div>
        <div>
          <img src="@/assets/images/gistda.png" alt="" />
        </div>
        <div>
          <img src="@/assets/images/gistda.png" alt="" />
        </div>
        <div>
          <img src="@/assets/images/gistda.png" alt="" />
        </div>
        <div>
          <img src="@/assets/images/gistda.png" alt="" />
        </div>
        <div>
          <img src="@/assets/images/gistda.png" alt="" />
        </div>
        <div>
          <img src="@/assets/images/gistda.png" alt="" />
        </div>
        <div>
          <img src="@/assets/images/gistda.png" alt="" />
        </div>
      </div>

      <div
        id="pricing"
        class="sp-w-full sp-relative sp-h-[450px] sp-rounded-md sp-bg-gradient-to-bl sp-from-[#237d7d] sp-via-black sp-via-90% sp-to-black sp-p-0.5"
      >
        <div
          class="sp-w-full sp-h-full sp-rounded-md sp-flex sp-items-center sp-justify-center"
        >
          <div>
            <h2 class="sp-text-5xl sp-font-bold sp-text-white sp-text-center">
              {{ $t("landing.pricingTopic") }}
            </h2>
            <p class="sp-mt-5 sp-font-thin">
              {{ $t("landing.pricing_des") }}
            </p>
            <div class="sp-flex sp-justify-center sp-pt-4">
              <button
                @click="signup"
                class="sp-rounded-lg sp-bg-[#2BFFFF] sp-px-5 sp-py-3"
                style="color: black"
              >
                {{ $t("landing.register") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ClientsComponent",
  methods: {
    signup() {
      window.location.href = this.$keycloak.createRegisterUrl({
        redirectUri:
          window.location.origin +
          this.$router.resolve({ name: "dashboard" }).href,
        locale: this.$i18n.locale,
      });
    },
  },
};
</script>
