var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    {
      staticClass:
        "sp-bg-white sphere-footer sp-border-gray-100 dark:sp-bg-gradient-to-r dark:sp-from-[#142828] dark:sp-via-[#080f0f] dark:sp-to-black dark:sp-shadow-lg dark:sp-border-b-2 dark:sp-border-gray-900",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "sp-grid sp-grid-cols-1 md:sp-grid-cols-2 sp-p-6 sp-items-center",
        },
        [
          _c("div", { staticClass: "sp-flex sp-gap-5 sp-items-end" }, [
            _vm._m(0),
            _c("div", { staticClass: "sp-flex sp-gap-3 sp-mb-2" }, [
              _c(
                "div",
                {
                  staticClass: "sp-w-7",
                  on: {
                    click: function ($event) {
                      return _vm.utility.openLink("mailto:sphere@gistda.or.th")
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/icons/mail.png"),
                      alt: "mail",
                    },
                  }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "sp-w-7",
                  on: {
                    click: function ($event) {
                      return _vm.utility.openLink(
                        "https://www.facebook.com/gistda"
                      )
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/icons/facebook.png"),
                      alt: "facebook",
                    },
                  }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "sp-w-7",
                  on: {
                    click: function ($event) {
                      return _vm.utility.openLink(
                        "https://www.instagram.com/gistda_space"
                      )
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/icons/instagram.png"),
                      alt: "ig",
                    },
                  }),
                ]
              ),
            ]),
          ]),
          _vm._m(1),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sp-w-40" }, [
      _c("img", {
        staticClass: "mt-5",
        attrs: { src: require("@/assets/images/gistda_2.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "sp-w-full sp-flex sp-justify-center lg:sp-justify-end sp-text-left",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "sp-w-full lg:sp-w-1/2 sp-text-center lg:sp-text-right sp-text-white",
          },
          [
            _c("p", [
              _vm._v("สำนักงานพัฒนาเทคโนโลยีอวกาศและภูมิสารสนเทศ สำนักงานใหญ่"),
            ]),
            _c("p", { staticClass: "sp-w sp-text-sm" }, [
              _vm._v(
                " เลขที่ 120 อาคารรวมหน่วยราชการ (อาคารรัฐประศาสนภักดี) ชั้น 6 และชั้น 7 ถนนแจ้งวัฒนะ แขวงทุ่งสองห้อง เขตหลักสี่ กรุงเทพฯ 10210 โทรศัพท์ 02 143 0567 "
              ),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }