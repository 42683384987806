<template>
  <section class="sp-bg-black sp-pt-64 sp-relative sphere-feature">
    <div
      class="sp-absolute sp-translate-y-[700px] sp-w-[400px] lg:sp-w-[400px] sp-bg-opacity-50 sp-h-[400px] sp-left-0 -sp-ml-32 sp-top-0 lg:sp-top-14 sp-bg-[#237d7d] sp-rounded-full sp-mix-blend-screen clip-half-circle -sp-rotate-90 sp-blur-[150px] sp-z-10"
    ></div>
    <div class="sp-grid sp-grid-cols-1 sp-gap-32 sp-px-10 2xl:sp-px-5 sp-relative">
      <div class="sp-max-w-screen-xl sp-mx-auto sp-text-white">
        <div
          id="apis"
          class="sp-grid sp-grid-cols-1 lg:sp-grid-cols-2 sp-gap-12 sp-items-center"
        >
          <div class="sp-flex sp-justify-center">
            <div class="sp-mb-12">
              <div>
                <img
                  class="sp-rounded-xl"
                  src="@/assets/images/map_api.png"
                  alt=""
                  srcset=""
                />
              </div>
            </div>
          </div>
          <div
            class="sp-flex sp-justify-center sp-text-left"
            on-scroll="fadeInUp"
          >
            <div class="sp-w-full">
              <h1 class="sp-text-4xl">Map APIs</h1>
              <h2>{{ $t("landing.apiTopic") }}</h2>
              <p class="sp-font-thin sp-mt-5 sp-mb-5">
                {{ $t("landing.apiDesc") }}
              </p>
              <button
                @click="utility.openLink(`${baseURI}/docs/`)"
                class="sp-rounded-3xl sp-border-white sp-border-[1px] sp-px-2 sp-py-1 explore-buttton"
              >
                Explore the APIs
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="service" class="sp-max-w-screen-xl sp-mx-auto sp-text-white">
        <div
          class="sp-grid sp-grid-cols-1 lg:sp-grid-cols-2 sp-gap-12 sp-items-center"
        >
          <div class="sp-flex lg:sp-hidden sp-justify-center">
            <div class="sp-mb-12">
              <div>
                <img
                  class="sp-rounded-xl"
                  src="@/assets/images/traffic.png"
                  alt=""
                  srcset=""
                />
              </div>
            </div>
          </div>
          <div
            class="sp-flex sp-justify-center sp-text-left"
            on-scroll="fadeInUp"
          >
            <div class="sp-w-full">
              <h1 class="sp-text-4xl">Web service</h1>
              <h2>{{ $t("landing.serviceTopic") }}</h2>
              <p class="sp-font-thin sp-mt-5 sp-mb-5">
                {{ $t("landing.serviceDes") }}
              </p>
              <button
                @click="utility.openLink(`${baseURI}/docs/`)"
                class="sp-rounded-3xl sp-border-white sp-border-[1px] sp-px-2 sp-py-1 explore-buttton"
              >
                Explore the Web Services
              </button>
            </div>
          </div>
          <div class="sp-hidden lg:sp-flex sp-justify-center">
            <div class="sp-mb-12">
              <div>
                <img
                  class="sp-rounded-xl"
                  src="@/assets/images/traffic.png"
                  alt=""
                  srcset=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sp-max-w-screen-xl sp-mx-auto sp-text-white">
        <div
          id="map-marker"
          class="sp-grid sp-grid-cols-1 lg:sp-grid-cols-2 sp-gap-12 sp-items-center"
        >
          <div class="sp-flex sp-justify-center">
            <div class="sp-mb-12">
              <div>
                <img
                  class="sp-rounded-xl"
                  src="@/assets/images/map-marker.png"
                  alt=""
                  srcset=""
                />
              </div>
            </div>
          </div>

          <div
            class="sp-flex sp-justify-center sp-text-left"
            on-scroll="fadeInUp"
          >
            <div class="sp-w-full">
              <h1 class="sp-text-4xl">Map Marker</h1>
              <h2>{{ $t("landing.portalTopic") }}</h2>
              <p class="sp-font-thin sp-mt-5 sp-mb-5">
                {{ $t("landing.portalDesc") }}
              </p>
              <button
                @click="utility.openLink(VUE_APP_PORTAL_URL)"
                class="sp-rounded-3xl sp-border-white sp-border-[1px] sp-px-2 sp-py-1 explore-buttton"
              >
                Explore the Map Marker
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="sp-w-full sp-max-w-screen-xl sp-mx-auto sp-text-white sp-text-left"
      >
        <div id="data-cube" class="sp-grid sp-grid-cols-1 sp-items-center">
          <div class="sp-flex sp-justify-center" on-scroll="fadeInDown">
            <div class="sp-w-full">
              <h1 class="sp-text-4xl">Data Cube</h1>
              <h2>
                {{ $t("landing.cubeTopic") }}
              </h2>
              <p class="sp-font-thin sp-mt-5 sp-mb-5">
                {{ $t("landing.cubeDesc") }}
              </p>
              <a
                href="https://datacube.gistda.or.th/"
                target="_blank"
                class="sp-rounded-3xl sp-border-white sp-border-[1px] sp-px-2 sp-py-1 explore-buttton"
              >
                Explore the Data Cube
              </a>
            </div>
          </div>
        </div>
        <div
          class="sp-h-[300px] md:sp-h-[400px] xl:sp-h-[500px] sp-animate-pulse sp-bg-[url('/assets/images/earth.png')] sp-bg-cover"
        ></div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "FeatureComponent",
  data() {
    return {
      baseURI: process.env.VUE_APP_BASE_URL,
      VUE_APP_PORTAL_URL: process.env.VUE_APP_PORTAL_URL,
    };
  },
};
</script>

<style lang="scss" scoped>
#apis {
  scroll-margin-top: 200px;
}
#service {
  scroll-margin-top: 200px;
}
#map-marker {
  scroll-margin-top: 200px;
}
#data-cube {
  scroll-margin-top: 200px;
}
.explore-buttton {
  padding: 10px 15px 10px 15px;
  color: white;
  border: 1px solid white;
}

@media screen and (max-width: 1024px) {
  .sphere-feature {
    padding-top: 4rem;
  }
}
</style>
