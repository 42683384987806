var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sp-bg-black", attrs: { id: "sphere-landing" } },
    [
      _c("LandingHeader"),
      _c("Hero"),
      _c("MainFeature"),
      _c("Feature"),
      _c("Solutions"),
      _c("Clients"),
      _c("Footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }