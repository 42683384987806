var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "sp-bg-[#0E0F0F] sp-pt-20", attrs: { id: "solutions" } },
    [
      _c("div", [
        _c("h1", { staticClass: "sp-text-white sp-text-center sp-text-4xl" }, [
          _vm._v(" " + _vm._s(_vm.$t("landing.solutions")) + " "),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "sp-max-w-screen-xl sp-mx-auto sp-justify-center" },
        [
          _c(
            "ul",
            {
              staticClass:
                "sp-flex sp-flex-wrap sp-justify-center -sp-mb-px sp-text-gray-500 sp-text-lg",
            },
            _vm._l(_vm.items, function (tab, index) {
              return _c("li", { key: index + 1, staticClass: "sp-me-4" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "sp-inline-block sp-text-white sp-p-4 sp-border-b-2 sp-border-transparent",
                    on: {
                      click: function ($event) {
                        return _vm.changeTab(index)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(tab.tabName) + " ")]
                ),
              ])
            }),
            0
          ),
          _c(
            "v-tabs-items",
            {
              staticClass: "sphere-solutions-content",
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            _vm._l(_vm.items, function (item, index) {
              return _c("v-tab-item", { key: index }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "sp-mx-auto sp-max-w-screen-xl sp-p-5 lg:sp-p-5",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "sp-grid sp-grid-cols-1 lg:sp-grid-cols-2 sp-items-center sp-gap-5",
                      },
                      [
                        _c("div", { staticClass: "showcase-img" }, [
                          _c("img", {
                            attrs: {
                              width: "100%",
                              src: _vm.utility.getImgSrc(item.tabImage),
                              alt: "no-image",
                              srcset: "",
                            },
                          }),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "sp-px-10 sp-py-10 sp-text-white sp-border-1 sp-rounded-3xl sulutions-content",
                          },
                          [
                            _c("h2", { staticClass: "sp-text-3xl" }, [
                              _vm._v(_vm._s(item.tabName)),
                            ]),
                            _c("p", {
                              key: _vm.locale,
                              staticClass:
                                "sp-pt-10 sp-text-gray-300 sp-font-thin",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.locale === "th"
                                    ? item.tabContent.th
                                    : item.tabContent.en
                                ),
                              },
                            }),
                            _c(
                              "button",
                              {
                                staticClass: "sulutions-explore",
                                on: {
                                  click: function ($event) {
                                    return _vm.utility.openLink(item.url)
                                  },
                                },
                              },
                              [_vm._v(" Explore " + _vm._s(item.tabName) + " ")]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ])
            }),
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }