<template>
  <section
    id="about"
    class="sp-relative sp-overflow-hidden sp-bg-gradient-to-b bg-gradient-to-b sp-from-black sp-to-black sm:sp-pb-16 sp-pt-40 lg:sp-pb-24 xl:sp-pb-20 xl:sp-pt-36"
  >
    <div class="sp-relative">
      <div
        class="sp-absolute sp-z-40 sp-inset-x-0 sp-flex sp-top-[10vh] sp-justify-center sp-h-[800px]"
      >
        <div
          class="sp-absolute sp-top-0 sp-bottom-0 sp-z-50 sp-w-[90%]"
          id="particles-js"
        ></div>
        <div
          class="sp-bg-gradient-to-t sp-from-black sp-to-teal-950 sp-rounded-full sp-blur-3xl sp-w-[80%] sp-text-white"
        ></div>
      </div>
      <div
        class="sp-absolute sp-pointer-events-none sp-bottom-0 sp-inset-x-0 sp-flex sp-translate-y-1/2 sp-justify-center sp-overflow-hidden [mask-image:radial-gradient(50%_50%_at_50%_90%,white,transparent)]"
      >
        <svg
          class="sp-h-[80rem] sp-w-[100rem] sp-flex-none sp-stroke-white sp-opacity-30"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e9033f3e-f665-41a6-84ef-756f6778e6fe"
              width="70"
              height="70"
              x="50%"
              y="50%"
              patternUnits="userSpaceOnUse"
              patternTransform="translate(-100,0)"
            >
              <path d="M.5 100V.5H100" fill="none"></path>
            </pattern>
          </defs>
          <rect
            width="100%"
            height="100%"
            stroke-width="0"
            fill="url(#e9033f3e-f665-41a6-84ef-756f6778e6fe)"
          ></rect>
        </svg>
      </div>
    </div>
    <div class="sp-mx-auto sp-px-2 lg:sp-px-8 sp-pt-5">
      <div class="sp-mx-auto sp-text-center">
        <h1
          class="sp-font-medium sp-tracking-tight sp-text-white sp-text-6xl sp-relative sp-z-50 animate__animated animate__fadeInDown"
        >
          {{ $t("landing.sphereTopic") }}
          <span
            class="sp-inline-block sp-text-transparent sp-bg-clip-text shimmer-animation"
            style="line-height: 72px"
          >
            {{ $t("landing.shepreTopicHighlight") }}
          </span>
        </h1>
        <div
          class="sp-flex sp-justify-center sp-relative sp-z-40 animate__animated animate__fadeInDown"
        >
          <h2
            class="sp-mt-6 sp-text-lg sp-max-w-screen-md sp-leading-8 sp-text-gray-400"
            v-html="$t('landing.sphereDesc')"
          ></h2>
        </div>
        <div
          class="sp-mt-10 sp-flex sp-items-center sp-justify-center sp-gap-x-6 sp-relative sp-z-50 animate__animated animate__fadeInUp"
        >
          <div class="glowing-wrapper glowing-wrapper-active">
            <div class="glowing-wrapper-animations">
              <div class="glowing-wrapper-glow"></div>
              <div class="glowing-wrapper-mask-wrapper">
                <div class="glowing-wrapper-mask"></div>
              </div>
            </div>
            <div class="glowing-wrapper-borders-masker">
              <div class="glowing-wrapper-borders"></div>
            </div>
            <button
              @click="onRegister"
              class="glowing-wrapper-button w-inline-block"
            >
              <div class="text-white">{{ $t("landing.register") }} &rarr;</div>
            </button>
          </div>
        </div>
      </div>

      <div class="sp-relative sp-mx-auto">
        <div class="sp-w-full sp-relative">
          <div class="sp-mx-auto sp-w-md sp-max-w-md"></div>
          <div class="sp-flex sp-justify-center sp-pt-10 sp-relative sp-z-50">
            <div
              class="sp-grid sp-grid-cols-2 lg:sp-grid-cols-4 sp-z-20 sp-gap-24"
            >
              <div class="shortcut-btn" @click="scrollTo('#apis')">
                <img
                  class="hover:sp-scale-110 sp-transform sp-transition sp-duration-500 animate__animated animate__fadeInUp"
                  src="@/assets/icons/api.png"
                  width="100px"
                  height="100px"
                  alt=""
                />
              </div>
              <div class="shortcut-btn" @click="scrollTo('#service')">
                <img
                  class="hover:sp-scale-110 sp-transform sp-transition sp-duration-500 animate__animated animate__fadeInUp"
                  src="@/assets/icons/web_service.png"
                  width="100px"
                  height="100px"
                  alt=""
                />
              </div>
              <div class="shortcut-btn" @click="scrollTo('#map-marker')">
                <img
                  class="hover:sp-scale-110 sp-transform sp-transition sp-duration-500 animate__animated animate__fadeInUp"
                  src="@/assets/icons/map_maker.png"
                  width="100px"
                  height="100px"
                  alt=""
                />
              </div>
              <div class="shortcut-btn" @click="scrollTo('#data-cube')">
                <img
                  class="hover:sp-scale-110 sp-transform sp-transition sp-duration-500 animate__animated animate__fadeInUp"
                  src="@/assets/icons/data_cube.png"
                  width="100px"
                  height="100px"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div class="sp-mt-0 xl:sp-mt-14 sp-relative sp-z-50 sp-p-6 lg:sp-p-0">
            <carousel-3d
              :autoplay="true"
              :width="600"
              :height="350"
              :border="0"
            >
              <slide
                :key="i"
                v-for="(slide, i) in slides"
                :index="i"
                style="border-radius: 15px"
              >
                <img style="border-radius: 15px" :src="slide.src" />
              </slide>
            </carousel-3d>
          </div>

          <div
            class="polar-bg sp-w-full sp-absolute sp-h-[800px] lg:sp-h-[700px] sp-bottom-56 sp-z-30 sp-animate-pulse"
          ></div>

          <div class="sp-min-h-[500px] sp-h-[500px]"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Carousel3d, Slide } from "vue-carousel-3d";
export default {
  name: "HeroComponent",
  components: {
    Carousel3d,
    Slide,
  },
  data() {
    return {
      slides: [
        {
          id: 1,
          src: "/img/slide/1.png",
        },
        {
          id: 2,
          src: "/img/slide/2.png",
        },
        {
          id: 3,
          src: "/img/slide/3.png",
        },
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      const particle = document.getElementById("particles-js");

      if (particle) {
        const particles = document.createElement("script");
        particles.src = "/js/particles-config.js";
        particles.id = "particles-js-script";
        if (!document.getElementById("particles-js-script")) {
          document.head.appendChild(particles);
        }
      }
    }, 1000);
  },
  methods: {
    onRegister() {
      window.location.href = this.$keycloak.createRegisterUrl({
        redirectUri:
          window.location.origin +
          this.$router.resolve({ name: "dashboard" }).href,
        locale: this.$i18n.locale,
      });
    },
    scrollTo(id) {
      document.querySelector(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.shortcut-btn:hover {
  cursor: pointer;
  transform: scale(1.2);
  transition: 0.5s;
}
.shimmer-animation {
  background: linear-gradient(
    to right,
    #31adad 41%,
    #58ffff 49%,
    #58ffff,
    #58ffff 52%,
    #31adad 60%
  );
  background-size: 200% 100%;
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-clip: text;
  -webkit-background-clip: text;
  override-colors: rgba(0, 0, 0, 0);
  animation: shimmer 10s linear 0s infinite normal;
  text-align: center;
}

@keyframes shimmer {
  0% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 100% 0%;
  }
}
</style>
