var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "sp-bg-black sp-pt-64 sp-relative sphere-feature" },
    [
      _c("div", {
        staticClass:
          "sp-absolute sp-translate-y-[700px] sp-w-[400px] lg:sp-w-[400px] sp-bg-opacity-50 sp-h-[400px] sp-left-0 -sp-ml-32 sp-top-0 lg:sp-top-14 sp-bg-[#237d7d] sp-rounded-full sp-mix-blend-screen clip-half-circle -sp-rotate-90 sp-blur-[150px] sp-z-10",
      }),
      _c(
        "div",
        {
          staticClass:
            "sp-grid sp-grid-cols-1 sp-gap-32 sp-px-10 2xl:sp-px-5 sp-relative",
        },
        [
          _c(
            "div",
            { staticClass: "sp-max-w-screen-xl sp-mx-auto sp-text-white" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "sp-grid sp-grid-cols-1 lg:sp-grid-cols-2 sp-gap-12 sp-items-center",
                  attrs: { id: "apis" },
                },
                [
                  _vm._m(0),
                  _c(
                    "div",
                    {
                      staticClass: "sp-flex sp-justify-center sp-text-left",
                      attrs: { "on-scroll": "fadeInUp" },
                    },
                    [
                      _c("div", { staticClass: "sp-w-full" }, [
                        _c("h1", { staticClass: "sp-text-4xl" }, [
                          _vm._v("Map APIs"),
                        ]),
                        _c("h2", [_vm._v(_vm._s(_vm.$t("landing.apiTopic")))]),
                        _c(
                          "p",
                          { staticClass: "sp-font-thin sp-mt-5 sp-mb-5" },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("landing.apiDesc")) + " "
                            ),
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass:
                              "sp-rounded-3xl sp-border-white sp-border-[1px] sp-px-2 sp-py-1 explore-buttton",
                            on: {
                              click: function ($event) {
                                return _vm.utility.openLink(
                                  _vm.baseURI + "/docs/"
                                )
                              },
                            },
                          },
                          [_vm._v(" Explore the APIs ")]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "sp-max-w-screen-xl sp-mx-auto sp-text-white",
              attrs: { id: "service" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "sp-grid sp-grid-cols-1 lg:sp-grid-cols-2 sp-gap-12 sp-items-center",
                },
                [
                  _vm._m(1),
                  _c(
                    "div",
                    {
                      staticClass: "sp-flex sp-justify-center sp-text-left",
                      attrs: { "on-scroll": "fadeInUp" },
                    },
                    [
                      _c("div", { staticClass: "sp-w-full" }, [
                        _c("h1", { staticClass: "sp-text-4xl" }, [
                          _vm._v("Web service"),
                        ]),
                        _c("h2", [
                          _vm._v(_vm._s(_vm.$t("landing.serviceTopic"))),
                        ]),
                        _c(
                          "p",
                          { staticClass: "sp-font-thin sp-mt-5 sp-mb-5" },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("landing.serviceDes")) + " "
                            ),
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass:
                              "sp-rounded-3xl sp-border-white sp-border-[1px] sp-px-2 sp-py-1 explore-buttton",
                            on: {
                              click: function ($event) {
                                return _vm.utility.openLink(
                                  _vm.baseURI + "/docs/"
                                )
                              },
                            },
                          },
                          [_vm._v(" Explore the Web Services ")]
                        ),
                      ]),
                    ]
                  ),
                  _vm._m(2),
                ]
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "sp-max-w-screen-xl sp-mx-auto sp-text-white" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "sp-grid sp-grid-cols-1 lg:sp-grid-cols-2 sp-gap-12 sp-items-center",
                  attrs: { id: "map-marker" },
                },
                [
                  _vm._m(3),
                  _c(
                    "div",
                    {
                      staticClass: "sp-flex sp-justify-center sp-text-left",
                      attrs: { "on-scroll": "fadeInUp" },
                    },
                    [
                      _c("div", { staticClass: "sp-w-full" }, [
                        _c("h1", { staticClass: "sp-text-4xl" }, [
                          _vm._v("Map Marker"),
                        ]),
                        _c("h2", [
                          _vm._v(_vm._s(_vm.$t("landing.portalTopic"))),
                        ]),
                        _c(
                          "p",
                          { staticClass: "sp-font-thin sp-mt-5 sp-mb-5" },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("landing.portalDesc")) + " "
                            ),
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass:
                              "sp-rounded-3xl sp-border-white sp-border-[1px] sp-px-2 sp-py-1 explore-buttton",
                            on: {
                              click: function ($event) {
                                return _vm.utility.openLink(
                                  _vm.VUE_APP_PORTAL_URL
                                )
                              },
                            },
                          },
                          [_vm._v(" Explore the Map Marker ")]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "sp-w-full sp-max-w-screen-xl sp-mx-auto sp-text-white sp-text-left",
            },
            [
              _c(
                "div",
                {
                  staticClass: "sp-grid sp-grid-cols-1 sp-items-center",
                  attrs: { id: "data-cube" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "sp-flex sp-justify-center",
                      attrs: { "on-scroll": "fadeInDown" },
                    },
                    [
                      _c("div", { staticClass: "sp-w-full" }, [
                        _c("h1", { staticClass: "sp-text-4xl" }, [
                          _vm._v("Data Cube"),
                        ]),
                        _c("h2", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("landing.cubeTopic")) + " "
                          ),
                        ]),
                        _c(
                          "p",
                          { staticClass: "sp-font-thin sp-mt-5 sp-mb-5" },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("landing.cubeDesc")) + " "
                            ),
                          ]
                        ),
                        _c(
                          "a",
                          {
                            staticClass:
                              "sp-rounded-3xl sp-border-white sp-border-[1px] sp-px-2 sp-py-1 explore-buttton",
                            attrs: {
                              href: "https://datacube.gistda.or.th/",
                              target: "_blank",
                            },
                          },
                          [_vm._v(" Explore the Data Cube ")]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
              _c("div", {
                staticClass:
                  "sp-h-[300px] md:sp-h-[400px] xl:sp-h-[500px] sp-animate-pulse sp-bg-[url('/assets/images/earth.png')] sp-bg-cover",
              }),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sp-flex sp-justify-center" }, [
      _c("div", { staticClass: "sp-mb-12" }, [
        _c("div", [
          _c("img", {
            staticClass: "sp-rounded-xl",
            attrs: {
              src: require("@/assets/images/map_api.png"),
              alt: "",
              srcset: "",
            },
          }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "sp-flex lg:sp-hidden sp-justify-center" },
      [
        _c("div", { staticClass: "sp-mb-12" }, [
          _c("div", [
            _c("img", {
              staticClass: "sp-rounded-xl",
              attrs: {
                src: require("@/assets/images/traffic.png"),
                alt: "",
                srcset: "",
              },
            }),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "sp-hidden lg:sp-flex sp-justify-center" },
      [
        _c("div", { staticClass: "sp-mb-12" }, [
          _c("div", [
            _c("img", {
              staticClass: "sp-rounded-xl",
              attrs: {
                src: require("@/assets/images/traffic.png"),
                alt: "",
                srcset: "",
              },
            }),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sp-flex sp-justify-center" }, [
      _c("div", { staticClass: "sp-mb-12" }, [
        _c("div", [
          _c("img", {
            staticClass: "sp-rounded-xl",
            attrs: {
              src: require("@/assets/images/map-marker.png"),
              alt: "",
              srcset: "",
            },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }