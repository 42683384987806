<template>
  <div>
    <div
      class="sp-absolute sp-w-1/2 sp-top-0 sp-right-0 sp-blur-3xl sp-h-32 sp-bg-gradient-to-l sp-z-20 sp-bg-[#2bffff37] sp-opacity-50 sp-pointer-events-none"
    ></div>
    <nav
      class="sp-fixed sp-w-full sp-z-[100] sp-flex sp-items-center sp-justify-between lg:sp-justify-center sp-py-6 sp-bg-white sp-border-gray-100 dark:sp-bg-gradient-to-r dark:sp-from-black dark:sp-via-[#080f0f] dark:sp-to-[#142828] dark:sp-shadow-lg dark:sp-border-b-2 dark:sp-border-gray-900"
    >
      <div
        class="sp-flex sp-items-center sp-flex-1 lg:sp-absolute lg:sp-inset-y-0 md:sp-left-5"
      >
        <div
          class="sp-flex sp-items-center sp-justify-between sp-w-full lg:sp-w-auto sp-pl-5 lg:sp-pl-0"
        >
          <a
            href="#"
            class="sp-flex sp-items-center sp-space-x-3 rtl:sp-space-x-reverse"
          >
            <img
              src="@/assets/images/logo-sphere-alt.png"
              class="sp-h-12"
              alt="sphere-logo"
            />
          </a>
          <div class="sp-pr-5 sp-flex sp-items-center lg:sp-hidden">
            <button
              type="button"
              id="main-menu"
              aria-label="Main menu"
              aria-haspopup="true"
              @click="toggleMenu()"
              class="inline-flex sp-items-center sp-justify-center sp-p-2 sp-rounded-md sp-text-gray-400 hover:sp-text-white hover:sp-bg-[#2bffff0e] focus:outline-none focus:sp-bg-[#2bffff0e] focus:sp-text-white sp-transition sp-duration-150 sp-ease-in-out"
            >
              <svg
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
                class="sp-h-6 sp-w-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>

      <!-- Desktop Menu -->
      <div class="sp-hidden lg:sp-flex lg:sp-space-x-10">
        <a
          href="#about"
          @click="onClickNav"
          class="sp-font-normal sp-text-white dark:sp-text-white hover:sp-text-white sp-transition sp-duration-150 sp-ease-in-out"
        >
          {{ $t("landing.navbar.about") }}
        </a>
        <a
          href="#apis"
          @click="onClickNav"
          class="sp-font-normal sp-text-white dark:sp-text-white hover:sp-text-white sp-transition sp-duration-150 sp-ease-in-out"
          >{{ $t("landing.navbar.api") }}</a
        >
        <a
          href="#map-marker"
          @click="onClickNav"
          class="sp-font-normal sp-text-white dark:sp-text-white hover:sp-text-white sp-transition sp-duration-150 sp-ease-in-out"
          >{{ $t("landing.navbar.mapMarker") }}</a
        >
        <a
          href="#data-cube"
          @click="onClickNav"
          class="sp-font-normal sp-text-white dark:sp-text-white hover:sp-text-white sp-transition sp-duration-150 sp-ease-in-out"
          >{{ $t("landing.navbar.dataCube") }}</a
        >
        <a
          href="#solutions"
          @click="onClickNav"
          class="sp-font-normal sp-text-white dark:sp-text-white hover:sp-text-white sp-transition sp-duration-150 sp-ease-in-out"
          >{{ $t("landing.navbar.solutions") }}</a
        >
        <a
          href="#pricing"
          @click="onClickNav"
          class="sp-font-normal sp-text-white dark:sp-text-white hover:sp-text-white sp-transition sp-duration-150 sp-ease-in-out"
        >
          {{ $t("landing.navbar.pricing") }}
        </a>
      </div>

      <!-- Language Switcher and Auth Links -->
      <div
        class="sp-hidden lg:sp-absolute lg:sp-flex lg:sp-items-center lg:sp-justify-end lg:sp-inset-y-0 lg:sp-right-5"
      >
        <div class="sp-inline-flex sp-text-sm sp-items-center">
          <button
            @click="changeLocale('th')"
            :class="
              $i18n.locale === 'th' ? 'sp-text-[#2BFFFF]' : 'sp-text-white'
            "
            class="sp-inline-flex sp-items-center sp-py-2 sp-border sp-border-transparent sp-text-base sp-leading-6 sp-font-normal focus:outline-none sp-transition sp-duration-150 sp-ease-in-out"
          >
            TH
          </button>
          <span class="sp-text-white sp-text-lg sp-pl-1 sp-pr-1">/</span>
          <button
            @click="changeLocale('en')"
            :class="
              $i18n.locale === 'en' ? 'sp-text-[#2BFFFF]' : 'sp-text-white'
            "
            class="inline-flex sp-items-center sp-py-2 sp-border sp-border-transparent sp-text-base sp-leading-6 sp-font-normal focus:outline-none sp-transition sp-duration-150 sp-ease-in-out"
          >
            EN
          </button>
        </div>
        <span class="sp-text-white sp-ml-2 sp-mr-2">|</span>
        <div class="profile" v-if="profile && profile.id">
          <v-btn
            disabled
            elevation="3"
            color="#c3c3c3"
            fab
            height="45"
            width="45"
          >
            <v-img
              v-if="profileImg"
              width="25"
              :src="profileImg"
              style="border-radius: 32px"
              alt="Profile Image"
            />
            <v-img
              v-else
              width="25"
              class="ma-4"
              :src="utility.getImgSrc('ic-account-white.svg')"
              alt="Profile Image"
            />
          </v-btn>
          <div
            class="main-header-profile text-left ml-3"
            style="width: 90px; line-height: 1.25rem"
          >
            <div class="one-line text-lg word-break-all font-weight-semi-bold">
              {{ profile.displayName || profile.username }}
            </div>
            <div
              v-if="profile.organization"
              class="text-gray text-sm font-weight-light one-line word-break-all"
            >
              {{ profile.organization }}
            </div>
          </div>
          <div>
            <v-menu offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon class="ml-3">
                  <v-icon large class="header-icon text-white"
                    >mdi-menu-down</v-icon
                  >
                </v-btn>
              </template>
              <v-list class="text-left py-0">
                <v-list-item :to="'/dashboard'">
                  <v-list-item-title>Dashboard</v-list-item-title>
                </v-list-item>
                <v-divider />
                <v-list-item @click="logout">
                  <v-list-item-title>{{ $t("app.logout") }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <span v-if="!profile" class="inline-flex">
          <button
            @click="login"
            class="inline-flex sp-items-center sp-py-2 sp-border sp-border-transparent sp-text-base sp-leading-6 sp-font-normal sp-text-white focus:outline-none sp-transition sp-duration-150 sp-ease-in-out"
          >
            {{ $t("landing.navbar.login") }}
          </button>
        </span>
        <span
          v-if="!profile"
          class="sp-inline-flex sp-rounded-lg sp-shadow sp-ml-2"
        >
          <button
            @click="signup"
            type="button"
            class="sp-text-black sp-bg-[#2BFFFF] sp-hover:bg-blue-800 sp-focus:ring-4 sp-focus:outline-none sp-focus:ring-blue-300 sp-font-th sp-rounded-3xl sp-text-sm sp-px-4 sp-py-2 sp-text-center dark:sp-bg[#2BFFFF] dark:sp-hover:bg-blue-700 dark:sp-focus:ring-blue-800"
          >
            {{ $t("landing.navbar.register") }}
          </button>
        </span>
      </div>

      <!-- Mobile Menu -->
      <div
        id="mobile-menu"
        class="sp-flex sp-text-left sp-flex-col sp-transition-all sp-max-h-0 sp-overflow-hidden dark:sp-bg-gradient-to-r dark:sp-from-black dark:sp-via-[#080f0f] dark:sp-to-[#142828] dark:sp-shadow-lg dark:sp-border-b-2 dark:sp-border-gray-900 sp-w-full sp-absolute sp-top-full sp-left-0 lg:sp-hidden"
      >
        <!-- Menu Items -->
        <a href="#about" class="sp-text-white sp-py-2 sp-px-4">About</a>
        <a href="#apis" class="sp-text-white sp-py-2 sp-px-4">APIs</a>
        <a href="#map-marker" class="sp-text-white sp-py-2 sp-px-4"
          >Map Marker</a
        >
        <a href="#data-cube" class="sp-text-white sp-py-2 sp-px-4">Data Cube</a>
        <a href="#solutions" class="sp-text-white sp-py-2 sp-px-4">Solutions</a>
        <a href="#pricing" class="sp-text-white sp-py-2 sp-px-4">Pricing</a>

        <!-- Divider -->
        <div class="sp-border-t sp-border-gray-700 sp-my-4"></div>

        <!-- Language Switcher -->
        <div class="sp-flex sp-justify-center sp-space-x-4 sp-py-2">
          <button
            @click="changeLocale('th')"
            :class="
              $i18n.locale === 'th'
                ? 'sp-text-[#2BFFFF] sp-font-normal'
                : 'sp-text-white sp-font-normal'
            "
          >
            TH
          </button>
          <span class="sp-text-white">/</span>
          <button
            @click="changeLocale('en')"
            :class="
              $i18n.locale === 'en'
                ? 'sp-text-[#2BFFFF] sp-font-normal'
                : 'sp-text-white sp-font-normal'
            "
          >
            EN
          </button>
        </div>

        <!-- Auth Links -->
        <div class="profile" v-if="profile && profile.id">
          <v-btn
            disabled
            elevation="3"
            color="#c3c3c3"
            fab
            height="45"
            width="45"
          >
            <v-img
              v-if="profileImg"
              width="25"
              :src="profileImg"
              style="border-radius: 32px"
              alt="Profile Image"
            />
            <v-img
              v-else
              width="25"
              class="ma-4"
              :src="utility.getImgSrc('ic-account-white.svg')"
              alt="Profile Image"
            />
          </v-btn>
          <div
            class="main-header-profile text-left ml-3"
            style="width: 90px; line-height: 1.25rem"
          >
            <div class="one-line text-lg word-break-all font-weight-semi-bold">
              {{ profile.displayName || profile.username }}
            </div>
            <div
              v-if="profile.organization"
              class="text-gray text-sm font-weight-light one-line word-break-all"
            >
              {{ profile.organization }}
            </div>
          </div>
          <div>
            <v-menu offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon class="ml-3">
                  <v-icon large class="header-icon text-white"
                    >mdi-menu-down</v-icon
                  >
                </v-btn>
              </template>
              <v-list class="text-left py-0">
                <v-list-item :to="'/dashboard'">
                  <v-list-item-title>Dashboard</v-list-item-title>
                </v-list-item>
                <v-divider />
                <v-list-item @click="logout">
                  <v-list-item-title>{{ $t("app.logout") }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <div
          v-if="!profile"
          class="sp-flex sp-justify-center sp-space-x-4 sp-mt-2 sp-mb-5"
        >
          <button
            @click="login"
            class="sp-text-white sp-px-4 sp-py-2 sp-rounded-3xl"
          >
            Log in
          </button>
          <button
            @click="signup"
            class="sp-bg-[#2BFFFF] sp-text-black sp-rounded-3xl sp-px-4 sp-py-2"
          >
            Sign up
          </button>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "LandingHeader",
  data() {
    return {
      profile: null,
      profileImg: null,
    };
  },
  mounted() {
    const profile = this.sessionManager.getUserProfile();
    if (profile) {
      this.profile = profile;
    }
  },
  methods: {
    onClickNav() {
      const hash = this.$route.hash;
      if (hash) {
        this.scroll(hash.substring(1));
      }
    },
    scroll(id) {
      const element = document.getElementById(id);
      if (element) {
        window.scrollTo({ top: id, behavior: "smooth" });
      }
    },
    changeLocale(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("locale", lang);
      let query = Object.assign({}, this.$route.query);
      query.lang = lang;
      this.$router.replace({ query });
    },
    login() {
      window.location.href = this.$keycloak.createLoginUrl({
        redirectUri:
          window.location.origin +
          this.$router.resolve({ name: "dashboard" }).href,
        locale: this.$i18n.locale,
      });
    },
    logout() {
      this.sessionManager.removeUserSession();
      this.profile = {};
      this.$keycloak.logout();
    },
    signup() {
      window.location.href = this.$keycloak.createRegisterUrl({
        redirectUri:
          window.location.origin +
          this.$router.resolve({ name: "dashboard" }).href,
        locale: this.$i18n.locale,
      });
    },
    toggleMenu() {
      const menu = document.getElementById("mobile-menu");
      if (menu.style.maxHeight) {
        menu.style.maxHeight = null;
      } else {
        menu.style.maxHeight = menu.scrollHeight + "px";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 500;
  color: white;
}
.gradient-container {
  top: 10px;
  width: 100%;
  position: relative;
  z-index: 200;
}
.gradient-line:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -75px;
  height: 75px;
  filter: blur(30px);
  -webkit-filter: blur(30px);
  background-image: conic-gradient(
    from 180deg at 50% 100%,
    transparent 0,
    transparent 27%,
    #ff3998 27%,
    #d764ff 36.04%,
    #00b5ff 58.23%,
    #0fd 63%,
    #54fa43 73%,
    transparent 73%,
    transparent 100%
  );
  animation: hueRotate 2.5s linear infinite;
}

@keyframes hueRotate {
  0% {
    filter: hue-rotate(0) blur(30px);
  }
  100% {
    filter: hue-rotate(360deg) blur(30px);
  }
}
@media screen and (max-width: 920px) {
  .profile {
    display: flex;
    justify-content: center;
  }
}
</style>
