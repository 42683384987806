var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass:
        "sp-relative sp-overflow-hidden sp-bg-gradient-to-b bg-gradient-to-b sp-from-black sp-to-black sm:sp-pb-16 sp-pt-40 lg:sp-pb-24 xl:sp-pb-20 xl:sp-pt-36",
      attrs: { id: "about" },
    },
    [
      _c("div", { staticClass: "sp-relative" }, [
        _vm._m(0),
        _c(
          "div",
          {
            staticClass:
              "sp-absolute sp-pointer-events-none sp-bottom-0 sp-inset-x-0 sp-flex sp-translate-y-1/2 sp-justify-center sp-overflow-hidden [mask-image:radial-gradient(50%_50%_at_50%_90%,white,transparent)]",
          },
          [
            _c(
              "svg",
              {
                staticClass:
                  "sp-h-[80rem] sp-w-[100rem] sp-flex-none sp-stroke-white sp-opacity-30",
                attrs: { "aria-hidden": "true" },
              },
              [
                _c("defs", [
                  _c(
                    "pattern",
                    {
                      attrs: {
                        id: "e9033f3e-f665-41a6-84ef-756f6778e6fe",
                        width: "70",
                        height: "70",
                        x: "50%",
                        y: "50%",
                        patternUnits: "userSpaceOnUse",
                        patternTransform: "translate(-100,0)",
                      },
                    },
                    [
                      _c("path", {
                        attrs: { d: "M.5 100V.5H100", fill: "none" },
                      }),
                    ]
                  ),
                ]),
                _c("rect", {
                  attrs: {
                    width: "100%",
                    height: "100%",
                    "stroke-width": "0",
                    fill: "url(#e9033f3e-f665-41a6-84ef-756f6778e6fe)",
                  },
                }),
              ]
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "sp-mx-auto sp-px-2 lg:sp-px-8 sp-pt-5" }, [
        _c("div", { staticClass: "sp-mx-auto sp-text-center" }, [
          _c(
            "h1",
            {
              staticClass:
                "sp-font-medium sp-tracking-tight sp-text-white sp-text-6xl sp-relative sp-z-50 animate__animated animate__fadeInDown",
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("landing.sphereTopic")) + " "),
              _c(
                "span",
                {
                  staticClass:
                    "sp-inline-block sp-text-transparent sp-bg-clip-text shimmer-animation",
                  staticStyle: { "line-height": "72px" },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("landing.shepreTopicHighlight")) + " "
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "sp-flex sp-justify-center sp-relative sp-z-40 animate__animated animate__fadeInDown",
            },
            [
              _c("h2", {
                staticClass:
                  "sp-mt-6 sp-text-lg sp-max-w-screen-md sp-leading-8 sp-text-gray-400",
                domProps: { innerHTML: _vm._s(_vm.$t("landing.sphereDesc")) },
              }),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "sp-mt-10 sp-flex sp-items-center sp-justify-center sp-gap-x-6 sp-relative sp-z-50 animate__animated animate__fadeInUp",
            },
            [
              _c(
                "div",
                { staticClass: "glowing-wrapper glowing-wrapper-active" },
                [
                  _vm._m(1),
                  _vm._m(2),
                  _c(
                    "button",
                    {
                      staticClass: "glowing-wrapper-button w-inline-block",
                      on: { click: _vm.onRegister },
                    },
                    [
                      _c("div", { staticClass: "text-white" }, [
                        _vm._v(_vm._s(_vm.$t("landing.register")) + " →"),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "sp-relative sp-mx-auto" }, [
          _c("div", { staticClass: "sp-w-full sp-relative" }, [
            _c("div", { staticClass: "sp-mx-auto sp-w-md sp-max-w-md" }),
            _c(
              "div",
              {
                staticClass:
                  "sp-flex sp-justify-center sp-pt-10 sp-relative sp-z-50",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "sp-grid sp-grid-cols-2 lg:sp-grid-cols-4 sp-z-20 sp-gap-24",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "shortcut-btn",
                        on: {
                          click: function ($event) {
                            return _vm.scrollTo("#apis")
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass:
                            "hover:sp-scale-110 sp-transform sp-transition sp-duration-500 animate__animated animate__fadeInUp",
                          attrs: {
                            src: require("@/assets/icons/api.png"),
                            width: "100px",
                            height: "100px",
                            alt: "",
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "shortcut-btn",
                        on: {
                          click: function ($event) {
                            return _vm.scrollTo("#service")
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass:
                            "hover:sp-scale-110 sp-transform sp-transition sp-duration-500 animate__animated animate__fadeInUp",
                          attrs: {
                            src: require("@/assets/icons/web_service.png"),
                            width: "100px",
                            height: "100px",
                            alt: "",
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "shortcut-btn",
                        on: {
                          click: function ($event) {
                            return _vm.scrollTo("#map-marker")
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass:
                            "hover:sp-scale-110 sp-transform sp-transition sp-duration-500 animate__animated animate__fadeInUp",
                          attrs: {
                            src: require("@/assets/icons/map_maker.png"),
                            width: "100px",
                            height: "100px",
                            alt: "",
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "shortcut-btn",
                        on: {
                          click: function ($event) {
                            return _vm.scrollTo("#data-cube")
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass:
                            "hover:sp-scale-110 sp-transform sp-transition sp-duration-500 animate__animated animate__fadeInUp",
                          attrs: {
                            src: require("@/assets/icons/data_cube.png"),
                            width: "100px",
                            height: "100px",
                            alt: "",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "sp-mt-0 xl:sp-mt-14 sp-relative sp-z-50 sp-p-6 lg:sp-p-0",
              },
              [
                _c(
                  "carousel-3d",
                  {
                    attrs: {
                      autoplay: true,
                      width: 600,
                      height: 350,
                      border: 0,
                    },
                  },
                  _vm._l(_vm.slides, function (slide, i) {
                    return _c(
                      "slide",
                      {
                        key: i,
                        staticStyle: { "border-radius": "15px" },
                        attrs: { index: i },
                      },
                      [
                        _c("img", {
                          staticStyle: { "border-radius": "15px" },
                          attrs: { src: slide.src },
                        }),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c("div", {
              staticClass:
                "polar-bg sp-w-full sp-absolute sp-h-[800px] lg:sp-h-[700px] sp-bottom-56 sp-z-30 sp-animate-pulse",
            }),
            _c("div", { staticClass: "sp-min-h-[500px] sp-h-[500px]" }),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "sp-absolute sp-z-40 sp-inset-x-0 sp-flex sp-top-[10vh] sp-justify-center sp-h-[800px]",
      },
      [
        _c("div", {
          staticClass: "sp-absolute sp-top-0 sp-bottom-0 sp-z-50 sp-w-[90%]",
          attrs: { id: "particles-js" },
        }),
        _c("div", {
          staticClass:
            "sp-bg-gradient-to-t sp-from-black sp-to-teal-950 sp-rounded-full sp-blur-3xl sp-w-[80%] sp-text-white",
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "glowing-wrapper-animations" }, [
      _c("div", { staticClass: "glowing-wrapper-glow" }),
      _c("div", { staticClass: "glowing-wrapper-mask-wrapper" }, [
        _c("div", { staticClass: "glowing-wrapper-mask" }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "glowing-wrapper-borders-masker" }, [
      _c("div", { staticClass: "glowing-wrapper-borders" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }