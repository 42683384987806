<template>
  <div>
    <section
      class="-sp-mt-52 sp-relative sp-bg-black sp-mx-auto sp-max-w-screen-xl sp-p-5 lg:sp-p-0"
    >
      <div class="preview">
        <div class="sp-flex sp-justify-center sp-text-white sp-text-center">
          <div>
            <h1 on-scroll="fadeInDown" class="sp-text-4xl">
              {{ $t("landing.mapLayerTopic") }}
            </h1>
            <p on-scroll="fadeInDown" class="sp-font-thin sp-text-lg sp-mt-5">
              {{ $t("landing.mapLayerTopicDes") }}
            </p>
          </div>
        </div>
        <div id="map-preview" class="map-preview sp-w-full sp-rounded-xl"></div>
      </div>

      <div class="sp-mt-10">
        <div class="sp-flex sp-justify-center sp-text-white sp-text-center">
          <div class="sp-mb-10">
            <h1 on-scroll="fadeInDown" class="sp-text-4xl">
              {{ $t("landing.colorBlindTopic") }}
            </h1>
          </div>
        </div>
        <div class="sp-flex sp-justify-center">
          <div class="sp-w-full">
            <div>
              <div
                class="sp-relative sp-bg-[url('/assets/images/red_green.png')] sp-bg-[size:100%_100%] sp-bg-no-repeat sp-flex sp-items-center sp-justify-center mobile-bg"
              >
                <img
                  src="@/assets/images/normal_mode.png"
                  width="600px"
                  class="sp-rounded-xl sp-absolute sp-pointer-events-none"
                  alt="normal_mode"
                  srcset=""
                />
                <img
                  src="@/assets/images/deuteranopia_mode.png"
                  id="compare-img"
                  width="600px"
                  style="clip-path: inset(0 50% 0 0)"
                  class="sp-rounded-xl sp-relative sp-pointer-events-none"
                  alt="deuteranopia_mode"
                />
                <div class="slider-bar">
                  <div
                    class="sp-absolute sp-z-50 sp-top-0 sp-left-1/2 sp-h-full sp-w-1 sp-bg-[#2BFFFF] sp-blur-[1px] sp-cursor-pointer"
                    id="slider-bar"
                  ></div>
                </div>
              </div>
            </div>
            <p class="sp-text-white sp-text-center sp-font-thin sp-text-lg">
              {{ $t("landing.colorBlindDes") }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="sp-bg-black">
      <div class="sp-flex sp-justify-center sp-relative">
        <div
          class="sp-absolute sp-opacity-20 sp-pointer-events-none sp-bottom-0 sp-flex sp-translate-y-0 sp-z-0 sp-justify-center sp-overflow-hidden [mask-image:radial-gradient(50%_50%_at_50%_50%,white,transparent)]"
        >
          <svg
            class="sp-h-[80rem] sp-w-[100%] sp-flex-none sp-stroke-white sp-opacity-30"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="e9033f3e-f665-41a6-84ef-756f6778e6fe"
                width="70"
                height="70"
                x="50%"
                y="50%"
                patternUnits="userSpaceOnUse"
                patternTransform="translate(-100,0)"
              >
                <path d="M.5 100V.5H100" fill="none"></path>
              </pattern>
            </defs>
            <rect
              width="100%"
              height="100%"
              stroke-width="0"
              fill="url(#e9033f3e-f665-41a6-84ef-756f6778e6fe)"
            ></rect>
          </svg>
        </div>
        <div
          class="sp-absolute sp-top-0 sp-bottom-0 sp-z-50 sp-w-[90%] sp-animate-pulse"
          id="particles-2"
        ></div>
        <div
          id="swiper-line"
          class="sp-absolute sp-z-50 sp-w-[300px] lg:sp-w-[800px] sp-top-0 lg:sp-top-14 sp-h-[400px] lg:sp-h-[800px] sp-bg-[#237d7d] sp-rounded-full sp-mix-blend-screen sp-blur-[200px]"
        ></div>

        <div
          class="sp-max-w-screen-xl sp-relative sp-z-30 disabled-person-ring-container"
        >
          <img
            class="disabled-ring"
            src="@/assets/images/disabled_person_ring.png"
            alt=""
          />
          <div class="disabled-description" style="color: white">
            {{ $t("landing.disabledPerson") }}
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  naem: "MainFeature",
  data() {
    return {
      map: null,
    };
  },
  created() {
    const self = this;
    const sphereMapScript = document.createElement("script");
    sphereMapScript.src = "https://api.sphere.gistda.or.th/map/?key=test2022";
    sphereMapScript.id = "sphere-map";

    if (!document.getElementById("sphere-map")) {
      document.head.appendChild(sphereMapScript);
    }
    sphereMapScript.onload = () => {
      setTimeout(() => {
        this.map = new window.sphere.Map({
          placeholder: document.getElementById("map-preview"),
          location: {
            lat: 13.722813,
            lon: 100.52933,
          },
          layer: window.sphere.Layers.STREETS_NIGHT,
          zoom: 10,
        });

        this.map.Event.bind("ready", () => {
          window.map = this.map;
          self.map.Ui.Scale.visible(false);
          self.map.Ui.Geolocation.visible(false);
          self.map.Ui.Zoombar.visible(false);
          self.map.Ui.Fullscreen.visible(false);
          self.map.Ui.DPad.visible(false);
          self.map.Ui.Scale.visible(false);
          self.map.Ui.Toolbar.visible(false);
          self.map.Renderer.setPitch(45);
          setTimeout(() => {
            // this.map.Layers.remove(window.sphere.Layers.IMAGES);
            this.map.Renderer.flyTo({
              center: [100.52933, 13.722813],
              zoom: 16.5,
              speed: 0.9,
              curve: 1,
              essential: true,
            });
          }, 1000);
          setTimeout(() => {
            this.map.Layers.add(window.sphere.Layers.TRAFFIC);
          }, 3000);
          setTimeout(() => {
            function rotateCamera(timestamp) {
              self.map.Renderer.rotateTo((timestamp / 100) % 360, {
                duration: 0,
              });
              requestAnimationFrame(rotateCamera);
            }

            rotateCamera(90);
          }, 7000);
        });
      }, 1000);
    };
  },
  mounted() {
    const sliderBar = document.getElementById("slider-bar");
    const compareImg = document.getElementById("compare-img");
    const container = sliderBar.parentElement;
    const elementToObserve = container;
    let isPlaying = false;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isPlaying) {
            isPlaying = true;
            playFiveSecondAction();
            observer.unobserve(elementToObserve);
          }
        });
      },
      { threshold: 1 }
    );
    observer.observe(elementToObserve);

    function playFiveSecondAction() {
      const rect = container.getBoundingClientRect();
      const maxPosition = rect.width;
      const duration = 5000;
      const frameRate = 1000 / 60;
      const totalFrames = duration / frameRate;
      let frame = 0;
      let direction = 1;
      const centerPosition = maxPosition / 2;
      sliderBar.style.left = `${centerPosition}px`;
      compareImg.style.clipPath = `inset(0 ${
        rect.width - centerPosition
      }px 0 0)`;

      const animated = setInterval(() => {
        const offset = (frame / totalFrames) * maxPosition * 0.5 * direction;
        const position = centerPosition + offset;

        sliderBar.style.left = `${position}px`;
        compareImg.style.clipPath = `inset(0 ${rect.width - position}px 0 0)`;
        if (Math.abs(offset) >= maxPosition / 2) {
          sliderBar.style.opacity = 0;
          sliderBar.style.transition = "opacity 2s";
          frame = totalFrames;
          setTimeout(() => {
            sliderBar.style.opacity = 1;
            sliderBar.style.transition = "opacity 2s";
            clearInterval(animated);
            compareImg.style.transition = "clip-path 2s";
            compareImg.style.clipPath = `inset(0 50% 0 0)`;
            sliderBar.style.left = `${centerPosition}px`;
          }, 2000);
          setTimeout(() => {
            compareImg.style.transition = "";
          }, 4000);
        } else {
          frame++;
        }
      }, frameRate);
    }

    sliderBar.onmousedown = (e) => {
      e.preventDefault();
      startDragging(e.clientX);
    };

    sliderBar.ontouchstart = (e) => {
      e.preventDefault();
      startDragging(e.touches[0].clientX);
    };

    function startDragging() {
      const rect = container.getBoundingClientRect();

      const handleMove = (position) => {
        let adjustedPosition = position - rect.left;
        adjustedPosition = Math.max(0, Math.min(adjustedPosition, rect.width));
        sliderBar.style.left = `${adjustedPosition}px`;
        compareImg.style.clipPath = `inset(0 ${
          rect.width - adjustedPosition
        }px 0 0)`;
      };

      const onMouseMove = (event) => handleMove(event.clientX);
      const onTouchMove = (event) => handleMove(event.touches[0].clientX);

      document.onmousemove = onMouseMove;
      document.ontouchmove = onTouchMove;

      document.onmouseup = () => endDragging();
      document.ontouchend = () => endDragging();

      function endDragging() {
        document.onmousemove = null;
        document.ontouchmove = null;
      }
    }
  },
};
</script>

<style>
.preview {
  pointer-events: none;
  margin-right: 10px;
  margin-left: 10px;
}
#map-preview {
  width: 100%;
  height: 600px;
}

.disabled-description {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 250px 250px 250px 250px;
  z-index: 1;
  height: 45%;
  width: 55.7%;
  color: white;
  background-color: rgba(1, 55, 55, 0.34);
  position: absolute;
  top: 50%;
  left: 50.5%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 2.5rem;
  padding: 50px;
}

.disabled-ring {
  position: relative;
  z-index: 10;
}

.mobile-bg {
  height: 800px;
}

.slider-bar {
  max-width: 600px;
  width: 100%;
  position: absolute;
  height: 650px;
}

@media screen and (max-width: 768px) {
  .disabled-person-ring-container {
    margin: 10px;
  }
  .mobile-bg {
    /* overflow: hidden; */
    margin-bottom: 15px;
    background: none !important;
    height: 450px;
  }

  #slider-bar {
    height: 450px !important;
  }
  .slider-bar {
    max-width: 600px;
    width: 100%;
    position: absolute;
    height: 450px;
  }
  .disabled-description {
    font-size: 0.9rem;
  }
}
</style>
